import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NavigationListener } from 'components/NavigationListener';
import { NhostClient } from '@nhost/react';
import { AuthGate } from 'containers/Authgate';
import { PostInstall } from 'containers/PostInstall';
import { TemplatePreview } from 'containers/TemplatePreview';
import { AcceptInvite } from 'containers/AcceptInvite';
import { PostPayment } from 'containers/PostPayment';
import { Login } from './containers/Login';
import { ForgotPassword } from './containers/ForgotPassword';
import SignUp from './containers/Signup';

const NhostApolloProvider = React.lazy(() =>
  import('@nhost/react-apollo').then((module) => ({
    default: module.NhostApolloProvider,
  })),
);

const NhostReactProvider = React.lazy(() =>
  import('@nhost/react').then((module) => ({
    default: module.NhostProvider,
  })),
);

const nhost = new NhostClient({
  subdomain: process.env.REACT_APP_NOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION,
});

const App = () => {
  const [isCrispLoaded, setIsCrispLoaded] = useState(false);

  useEffect(() => {
    // Load the crispr chat window in prodcution
    if (!isCrispLoaded && process.env.NODE_ENV !== 'development') {
      // @ts-ignore
      window.$crisp = [];
      // @ts-ignore
      window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;
      (function bootstrapCrisp() {
        var d = document;
        var s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = true;
        d.getElementsByTagName('head')[0].appendChild(s);
        setIsCrispLoaded(true);
      })();
    }
  }, [isCrispLoaded, setIsCrispLoaded]);

  return (
    <Suspense fallback={<div></div>}>
      <NhostReactProvider nhost={nhost}>
        <NhostApolloProvider nhost={nhost}>
          <BrowserRouter>
            <NavigationListener />
            <Routes>
              <Route path="invite/accept" element={<AcceptInvite />} />
              <Route path="post-install" element={<PostInstall />} />
              <Route
                path="post-install/:id/:secret"
                element={<PostInstall />}
              />
              <Route path="post-payment" element={<PostPayment />} />
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="sign-up" element={<SignUp />} />
              <Route path="template-preview" element={<TemplatePreview />} />
              <Route path="*" element={<AuthGate />} />
            </Routes>
          </BrowserRouter>
        </NhostApolloProvider>
      </NhostReactProvider>
    </Suspense>
  );
};

export default App;
