import React from 'react';
import Logo from '../assets/images/shipaid_logo_300x300.webp';

export const Loader = ({ text = '' }: { text?: string }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div
        style={{
          borderColor: 'rgba(37,99,235, 0.2)',
          borderTopColor: '#2564eb',
        }}
        className="inline-block border-4 rounded-full animate-spin w-16 h-16"
        role="spinbutton"
      />
      <img alt="Loading" src={Logo} className="w-8 h-8 absolute" />

      {text && <div className="absolute mt-24 font-bold">{text}</div>}
    </div>
  );
};
