import { useCallback, useEffect, useState } from 'react';
import { DataTable } from 'components/DataTable';
import { SearchBox } from 'components/Search';
import { Modal } from 'components/Modal';
import { columns } from './data/install-request';
import { useLazyQuery, useMutation } from '@apollo/client';
import { withNotification } from 'components/Notification';
import { NotificationTypes } from 'utils';
import { LoadingScreen } from 'components/LoadingScreen';
import { GET_INSTALL_REQUESTS } from 'gql/queries';
import { InstallRequest } from 'types/install-requests';
import { UPDATE_INSTALL_REQUEST } from 'gql/mutations';

export const InstallRequests = withNotification(({ showNotification }: any) => {
  const [requests, setRequests] = useState<[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<InstallRequest | null>(
    null,
  );
  const [newInstallLink, setNewInstallLink] = useState('');

  const [getInstallRequests, { loading }] = useLazyQuery(GET_INSTALL_REQUESTS, {
    onCompleted: (data) => {
      setRequests(data.installRequests);
    },
    onError(error) {
      showNotification(NotificationTypes.error, error?.message);
    },
  });

  const [updateInstallLink] = useMutation(UPDATE_INSTALL_REQUEST, {
    onCompleted: () => {
      showNotification(
        NotificationTypes.success,
        'Install link updated successfully',
      );
      fetchRequests();
      setIsModalOpen(false);
    },
    onError: (error) => {
      showNotification(NotificationTypes.error, error?.message);
    },
  });

  const fetchRequests = useCallback(() => {
    getInstallRequests({
      variables: {
        where: {},
      },
    });
  }, [getInstallRequests]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  const handleUpdateInstallLink = (request: InstallRequest) => {
    setSelectedRequest(request);
    setNewInstallLink(request.installLink || '');
    setIsModalOpen(true);
  };

  const handleSaveInstallLink = () => {
    if (selectedRequest) {
      updateInstallLink({
        variables: {
          _set: {
            installLink: newInstallLink,
            status: 'COMPLETED',
          },
          where: {
            id: {
              _eq: selectedRequest.id,
            },
          },
        },
      });
    }
  };

  if (loading) return <LoadingScreen />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div className="mb-4 sm:mb-4 md:mb-2 flex items-center">
        <div className="mb-4 sm:mb-4 md:mb-2 flex items-center w-full">
          <div className="w-full">
            <SearchBox
              search={() => {}}
              searchValue={searchValue}
              onChange={(searchValue) => setSearchValue(searchValue)}
              placeholder="Search by email"
            />
          </div>
        </div>
      </div>
      <div className="">
        <DataTable
          data={requests.map((r: InstallRequest) => ({
            ...(r || {}),
            onUpdateInstallLink: () => handleUpdateInstallLink(r),
          }))}
          columns={columns}
          emptyMessage={
            searchValue
              ? `No search results matches "${searchValue}"`
              : 'No data'
          }
        />
      </div>

      <Modal open={isModalOpen} openModal={() => setIsModalOpen(false)}>
        <div className="p-4 w-[500px]">
          <h2 className="text-lg font-medium mb-4">Update Install Link</h2>
          <input
            type="text"
            value={newInstallLink}
            onChange={(e) => setNewInstallLink(e.target.value)}
            className="border p-2 w-full mb-4"
            placeholder="Enter new install link"
          />
          <div className="flex justify-end">
            <button
              className="mr-2 px-4 py-2 bg-gray-300 text-gray-800"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-blue-600 text-white"
              onClick={handleSaveInstallLink}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
});
