import React from 'react';
import { Link } from 'react-router-dom';

const Success: React.FC = () => {
  return (
    <div className="text-center">
      <div className="relative w-32 h-32 mx-auto">
        <div className="absolute inset-0 bg-green-100 rounded-full animate-pulse"></div>
        <svg
          className="relative w-full h-full text-green-500 transform scale-50"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 6L9 17L4 12"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="animate-draw"
          />
        </svg>
      </div>
      <h2 className="mt-8 text-4xl font-extrabold text-gray-900 tracking-tight">
        Congratulations!
      </h2>
      <p className="mt-6 text-xl text-gray-600 max-w-2xl mx-auto leading-relaxed">
        Your store setup is complete and you&apos;re ready to start selling.
        We&apos;re excited to help you grow your business!
      </p>
      <div className="space-x-4">
        <Link
          to="/dashboard"
          className="inline-block mt-8 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
        >
          Back to Dashboard
        </Link>
        <Link
          to="/settings/install"
          className="inline-block mt-8 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
        >
          Go to Install Widget
        </Link>
      </div>
    </div>
  );
};

export default Success;
