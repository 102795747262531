import { useMemo } from 'react';
import { useStore } from 'context/store-context';
import { useUser } from 'context/user-context';

const useCustomApp = () => {
  const { user } = useUser();
  const { storeProperties, storeId, userStores } = useStore();

  const hasCustomAppConnected = useMemo(() => {
    return Boolean(
      storeId &&
        userStores?.length &&
        !storeProperties?.uninstalledAt &&
        storeProperties?.useCustomApp,
    );
  }, [storeId, userStores, storeProperties]);

  const hasCompletedCustomAppSetup = useMemo(() => {
    return storeProperties?.billing && hasCustomAppConnected;
  }, [storeProperties, hasCustomAppConnected]);

  const hasPartiallyCompleted = useMemo(() => {
    return storeProperties?.billing || hasCustomAppConnected;
  }, [storeProperties, hasCustomAppConnected]);
  return {
    storeId,
    isLoading: (storeId && typeof storeProperties === 'undefined') || !user,
    hasCompletedCustomAppSetup,
    hasPartiallyCompleted,
    hasCustomApp: storeProperties?.useCustomApp || false,
    hasBilling: storeProperties?.billing || false,
    enableCustomAppFlow:
      user?.metadata?.enableCustomAppFlow ||
      storeProperties?.enableCustomAppFlow,
  };
};

export default useCustomApp;
