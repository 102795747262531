import { Text } from 'components/Text';
import { useStore } from 'context/store-context';
import { useEffect, useRef, useState } from 'react';
import ShopifyLogo from '../../assets/images/shopify-logo.svg';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_INSTALL_REQUESTS } from 'gql/queries';
import { useUser } from 'context/user-context';
import { Button } from 'components/Button';
import ExpertInstallPopup from './ExpertInstallPopup';
import { Link } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { UPDATE_STORE } from 'gql/mutations';

interface ShopifyConnectionProps {
  onComplete: (domain: string, accessToken: string) => void;
  isConnected: boolean;
  isLoading: boolean;
  onAskShipAid: (name: string, email: string, storeName: string) => void;
  isOnAskShipAidLoading: boolean;
}

const ShopifyConnection = ({
  onComplete,
  isConnected,
  isLoading,
  onAskShipAid,
  isOnAskShipAidLoading,
}: ShopifyConnectionProps) => {
  let ticketFetchInterval = useRef<NodeJS.Timer | null>(null);

  const store = useStore();
  const { user } = useUser();

  const [domain, setDomain] = useState(store.storeDomain);
  const [installLink, setInstallLink] = useState('');
  const [ticketStatus, setTicketStatus] = useState<string | null>(null);
  const [isCustomAppCreated, setIsCustomAppCreated] = useState<boolean>(false);

  const [showExpertInstallPopup, setExpertInstallPopup] =
    useState<boolean>(false);

  const toggleExpertInstallPopup = () => setExpertInstallPopup((open) => !open);

  const [updateStore] = useMutation(UPDATE_STORE);

  const handleSubmit = () => {
    if (domain && installLink) {
      onComplete(domain, installLink);
      updateStore({
        variables: {
          storeId: store.storeId,
          set: {
            customAppInstallLink: installLink,
          },
        },
      });
    }
  };

  const [, { refetch: refetchInstallRequests }] = useLazyQuery(
    GET_INSTALL_REQUESTS,
    {
      variables: {
        where: {
          email: {
            _eq: user?.email,
          },
          isCustomApp: {
            _eq: true,
          },
        },
      },
      onCompleted: (data) => {
        if (data?.installRequests?.length) {
          if (data.installRequests[0].status !== 'COMPLETED') {
            setTicketStatus(data.installRequests[0].status);
          } else {
            setTicketStatus(null);
            setIsCustomAppCreated(true);
          }
          if (!installLink) {
            setInstallLink(data.installRequests[0].installLink);
          }
        } else {
          setTicketStatus(null);
        }
      },
      onError(error) {},
    },
  );

  useEffect(() => {
    if (!ticketStatus && isCustomAppCreated) {
      if (ticketFetchInterval.current)
        clearInterval(ticketFetchInterval.current);
      return;
    }

    ticketFetchInterval.current = setInterval(() => {
      refetchInstallRequests();
    }, 2000);

    return () => {
      if (ticketFetchInterval.current)
        clearInterval(ticketFetchInterval.current);
    };
  }, [isCustomAppCreated, ticketStatus, refetchInstallRequests]);

  useEffect(() => {
    if (!installLink && store?.storeProperties?.customAppInstallLink) {
      setInstallLink(store?.storeProperties?.customAppInstallLink);
    }
  }, [installLink, store?.storeProperties?.customAppInstallLink]);

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
        <div className="flex flex-col items-center text-center mb-8">
          <img src={ShopifyLogo} alt="Shopify" className="h-16 w-16 mb-4" />
          <div>
            <Text
              value="Connect Your Shopify Store"
              type="h3"
              className="text-2xl font-bold text-gray-900 mb-3"
            />
            <Text
              value="Enter your store domain and access token to connect your Shopify store with ShipAid"
              className="text-gray-600 max-w-md mx-auto"
            />
          </div>
        </div>
        <div className="flex flex-col items-start space-y-2 mb-4">
          <Button
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200 ease-in-out shadow-md"
            onClick={toggleExpertInstallPopup}
          >
            Expert Install
          </Button>
          <Link
            to="/"
            className="flex items-center text-blue-500 hover:underline hover:text-blue-700 transition duration-200"
          >
            <InformationCircleIcon className="w-6 h-6 mt-1 mr-1" /> Learn more
            about App Permissions
          </Link>
        </div>

        {isConnected ? (
          <div className="flex items-center text-green-600 mb-4">
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span>Successfully Connected</span>
          </div>
        ) : (
          <div className="space-y-4">
            <div>
              <label
                htmlFor="domain"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Shopify Store Domain
              </label>
              <input
                type="text"
                id="domain"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                placeholder="your-store.myshopify.com"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="accessToken"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Install Link
              </label>
              <input
                type="text"
                id="installLink"
                value={installLink}
                onChange={(e) => setInstallLink(e.target.value)}
                placeholder="https://admin.shopify.com/oauth/install_custom_app..."
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
            {!isConnected && !installLink && (
              <div className="mt-6 space-y-4">
                {isCustomAppCreated ? (
                  <div className="space-y-4">
                    <div className="bg-green-100 text-green-800 p-4 rounded-md text-center">
                      <p className="font-medium">
                        A custom app has been created for you.
                      </p>
                      <p className="text-sm mt-1">
                        Please contact the ShipAid team to obtain your Install
                        Link.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="bg-yellow-100 text-yellow-800 p-4 rounded-md text-center">
                    <p className="font-medium">
                      Custom App has not been created yet?
                    </p>
                    <p className="text-sm mt-1">
                      Please try again later or contact support for assistance.
                    </p>
                    {!ticketStatus ? (
                      <button
                        disabled={!domain || isOnAskShipAidLoading}
                        onClick={onAskShipAid.bind(
                          null,
                          String(user?.displayName),
                          String(user?.email),
                          String(domain),
                        )}
                        className="w-full my-2 bg-blue-500 text-white py-1 px-1 rounded-md hover:bg-blue-600 transition-colors"
                      >
                        {isOnAskShipAidLoading
                          ? 'Please wait...'
                          : 'Request ShipAid Team'}
                      </button>
                    ) : (
                      <div className="mt-6">
                        <div className="bg-blue-100 text-blue-800 p-4 rounded-md shadow-md">
                          <div className="flex justify-between items-center">
                            <p className="font-semibold text-lg">
                              Ticket Status:
                            </p>
                            <p className="text-sm">{ticketStatus}</p>
                          </div>
                          <p className="text-sm mt-3 text-blue-600 italic">
                            We have received your request.
                            <br />
                            Please be patient while we create the app for you.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {(!ticketStatus || installLink) && (
              <button
                onClick={handleSubmit}
                disabled={!domain || !installLink || isLoading}
                className="w-full bg-blue-500 text-white py-3 px-4 rounded-md hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? 'Please wait...' : 'Install'}
              </button>
            )}
          </div>
        )}
      </div>
      {showExpertInstallPopup && (
        <ExpertInstallPopup onClose={toggleExpertInstallPopup} />
      )}
    </div>
  );
};

export default ShopifyConnection;
