import { Text } from 'components/Text';
import StripeLogo from '../../assets/images/stripe-logo-svg.svg';
import { useEffect, useState } from 'react';
import { useUser } from 'context/user-context';

interface StripeSetupProps {
  isLoading: boolean;
  onComplete: (email: string) => void;
}

const StripeSetup = ({ isLoading, onComplete }: StripeSetupProps) => {
  const [email, setEmail] = useState<string>('');

  const { user } = useUser();

  useEffect(() => {
    if (user?.email) {
      setEmail(user.email);
    }
  }, [user?.email]);

  const isValidEmail = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email);

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
        <div className="flex flex-col items-center text-center mb-8">
          <img src={StripeLogo} alt="Stripe" className="h-16 w-16 mb-4" />
          <div>
            <Text
              value="Connect Stripe Account"
              type="h3"
              className="text-2xl font-bold text-gray-900 mb-3"
            />
            <Text
              value="Set up secure payments with Stripe to process transactions"
              className="text-gray-600 max-w-md mx-auto"
            />
          </div>
        </div>

        <div className="items-center my-2 mb-5">
          <Text value="Email" type="sm" className="text-gray-500 mr-3 mb-1" />
          <input
            name="senderEmail"
            className="w-full border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block text-md sm:text-sm border-gray-300 rounded-md py-4 px-2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {email && !isValidEmail && (
            <span className="text:sm font:bold text-red-400">
              Please enter a valid email
            </span>
          )}
        </div>

        <button
          disabled={!isValidEmail}
          onClick={onComplete.bind(null, email)}
          className="w-full bg-[#6772E5] text-white py-3 px-4 rounded-md hover:bg-[#5965e2] transition-colors"
        >
          {isLoading ? 'Please wait...' : 'Connect Stripe Account'}
        </button>
      </div>
    </div>
  );
};

export default StripeSetup;
