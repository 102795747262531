import React, { useState } from 'react';
import { Text } from '../Text';
import { useAppOnboarding } from 'hooks/useAppOnboarding';
import ExpertInstallPopup from 'components/CustomAppOnboarding/ExpertInstallPopup';

const CompleteOnboardingAlert: React.FC = () => {
  const { currentStep } = useAppOnboarding();

  const [showExpertInstallPopup, setExpertInstallPopup] =
    useState<boolean>(false);

  const toggleExpertInstallPopup = () => setExpertInstallPopup((open) => !open);

  if (currentStep > 2) return null;

  return (
    <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-amber-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <Text
            value="Attention: Complete Your Onboarding"
            type="h3"
            className="text-orange-600 font-medium"
          />
          <div className="mt-2 text-orange-700">
            <Text
              value="To unlock the full potential of the app, please complete the onboarding process."
              className="text-sm"
            />
            <div className="flex items-center mt-3 mb-2">
              {/* Install App Step */}
              <div className="flex items-center">
                <div
                  className={`w-6 h-6 rounded-full flex items-center justify-center ${
                    currentStep > 1 ? 'bg-green-500' : 'bg-amber-200'
                  }`}
                >
                  {currentStep > 1 ? (
                    <svg
                      className="w-4 h-4 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <span className="text-orange-600 text-sm">1</span>
                  )}
                </div>
                <span className="ml-2 text-sm text-orange-600">
                  Install App
                </span>
              </div>

              {/* Connector */}
              <div
                className={`mx-2 h-0.5 w-8 ${
                  currentStep === 2 ? 'bg-green-500' : 'bg-amber-200'
                }`}
              />

              {/* Authorize Billing Step */}
              <div className="flex items-center">
                <div
                  className={`w-6 h-6 rounded-full flex items-center justify-center ${
                    currentStep > 2 ? 'bg-green-500' : 'bg-amber-200'
                  }`}
                >
                  {currentStep > 2 ? (
                    <svg
                      className="w-4 h-4 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <span className="text-orange-600 text-sm">2</span>
                  )}
                </div>
                <span className="ml-2 text-sm text-orange-600">
                  Authorize Billing
                </span>
              </div>
            </div>
            <button
              className="mt-3 bg-orange-600 text-white px-4 py-2 rounded-md text-sm hover:bg-orange-700 transition-colors"
              onClick={toggleExpertInstallPopup}
            >
              Expert Install
            </button>
            <button
              className="mt-3 ml-4 border border-orange-600 text-orange-700 px-4 py-2 rounded-md text-sm hover:bg-orange-100 transition-colors"
              onClick={() => (window.location.href = '/install')}
            >
              {currentStep > 2 ? 'Completed' : 'Complete Onboarding'}
            </button>
          </div>
        </div>
      </div>
      {showExpertInstallPopup && (
        <ExpertInstallPopup onClose={toggleExpertInstallPopup} />
      )}
    </div>
  );
};

export default CompleteOnboardingAlert;
