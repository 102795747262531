import React from 'react';
import { Text } from 'components/Text';
import { InstallRequest } from 'types/install-requests';
import { Button } from 'components/Button';
import { Link } from 'react-router-dom';

export const columns = [
  {
    name: 'Name',
    id: 1,
    render: (item: InstallRequest) => (
      <div className="flex items-center">
        <Text value={item.name} />
      </div>
    ),
  },
  {
    name: 'Email',
    id: 2,
    key: 'email',
  },
  {
    name: 'Ticket Link',
    id: 3,
    render: (item: InstallRequest) => (
      <div className="flex items-center">
        <Link to={item.ticketLink}>Link</Link>
      </div>
    ),
  },
  {
    name: 'Custom App',
    id: 4,
    render: (item: InstallRequest) => (
      <div className="flex items-center">
        {item.isCustomApp ? 'CUSTOM' : 'PUBLIC'}
      </div>
    ),
  },
  {
    name: 'Install Link',
    id: 4,
    render: (item: InstallRequest) => (
      <div className="flex items-center">
        {item.installLink ? <Link to={item.installLink}>Link</Link> : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Status',
    id: 4,
    key: 'status',
  },
  {
    name: 'Action',
    id: 2,
    render: (item: InstallRequest) => (
      <Button onClick={item.onUpdateInstallLink}>Update Install Link</Button>
    ),
  },
];
