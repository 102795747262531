import React, { useEffect } from 'react';
import { Text } from '../Text';
import { useAppOnboarding } from 'hooks/useAppOnboarding';
import { StoreSelection } from './StoreSelection';
import { MerchantBilling } from './MerchantBilling';
import { useNavigate } from 'react-router';
import Success from './Success';
import { Loader } from 'components/Loader';

const steps = [
  { id: 1, title: 'Install ShipAid' },
  { id: 2, title: 'Authorize Billing' },
  { id: 3, title: 'Complete' },
];

const AppOnboarding: React.FC = () => {
  const {
    currentStep,
    setCurrentStep,
    waitingPayment,
    isLoading,
    enableCustomAppFlow,
  } = useAppOnboarding();
  const navigate = useNavigate();

  useEffect(() => {
    if (enableCustomAppFlow) {
      navigate('/dashboard');
    }
  }, [enableCustomAppFlow, navigate]);

  const renderStepContent = (step: number) => {
    if (isLoading) {
      return (
        <div className="mt-20 w-24 h-24">
          <Loader text="Please wait..." />
        </div>
      );
    }
    switch (step) {
      case 1:
        return <StoreSelection onNextStep={() => setCurrentStep(2)} />;
      case 2:
        return <MerchantBilling waitingPayment={waitingPayment} />;
      case 3:
        return <Success />;
      default:
        return null;
    }
  };

  return (
    <div className="mx-auto p-6">
      <div className="text-center mb-8">
        <Text
          value="Complete These Essential Steps"
          type="h2"
          className="text-3xl font-bold text-blue-700 mb-2"
        />
        <Text
          value="These steps are mandatory to set up your account properly"
          className="text-gray-600"
        />
      </div>
      <div>
        <div className="flex max-w-3xl mx-auto items-center justify-between">
          {steps.map((step) => (
            <React.Fragment key={step.id}>
              <div className="flex flex-col relative items-center">
                <div className="relative">
                  <div
                    className={`w-10 h-10 rounded-full flex items-center justify-center ${
                      step.id <= currentStep
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-200 text-gray-500'
                    }`}
                  >
                    {step.id}
                  </div>
                  <div
                    className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-14 h-14 rounded-full ${
                      step.id <= currentStep
                        ? 'border-2 border-blue-500'
                        : 'border-2 border-dashed border-gray-300'
                    }`}
                  ></div>
                </div>
                <Text
                  value={step.title}
                  className={`absolute whitespace-nowrap mt-12 text-sm ${
                    step.id <= currentStep ? 'text-blue-500' : 'text-gray-500'
                  }`}
                />
              </div>
              {step.id < steps.length && (
                <div
                  className={`flex-1 h-1 mx-2 ${
                    step.id < currentStep ? 'bg-blue-500' : 'bg-gray-200'
                  }`}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="flex justify-center py-2 items-center bg-white rounded-lg shadow-md pt-0 px-4 pb-4">
        <div className="mt-8">{renderStepContent(currentStep)}</div>
      </div>
    </div>
  );
};

export default AppOnboarding;
