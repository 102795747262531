/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import classNames from 'classnames';

type Tab = {
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  name: string;
  href?: string;
};

type TabsProps = {
  tabs: Tab[];
  selectedTab: string;
  setSelectedTab: (val: string) => void;
};

export const Tabs = ({ tabs, selectedTab, setSelectedTab }: TabsProps) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          defaultValue={selectedTab}
          onChange={(e) => {
            e.preventDefault();
            setSelectedTab(e.target.value);
          }}
        >
          {tabs.map((tab) => (
            <Fragment key={tab.name}>
              <option className="flex items-center">
                {tab.icon && <tab.icon className="h-5 w-5 mr-2" />}
                {tab.name}
              </option>
            </Fragment>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
            {tabs.map((tab) => (
              <>
                <a
                  key={tab.name}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedTab(tab.name);
                    if (tab.href && tab.href !== '#') {
                      window.location.href = tab.href;
                    }
                  }}
                  style={{ cursor: 'pointer' }}
                  className={classNames(
                    tab.name === selectedTab
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center',
                  )}
                  aria-current={tab.name === selectedTab ? 'page' : undefined}
                >
                  {tab.icon && <tab.icon className="h-5 w-5 mr-2" />}
                  {tab.name}
                </a>
              </>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
