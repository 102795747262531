import { useStore } from 'context/store-context';
import { useUser } from 'context/user-context';
import { useState, useEffect, useCallback } from 'react';

export const useAppOnboarding = () => {
  const { user } = useUser();
  const storeContext = useStore();

  const [onboardingState, setOnboardingState] = useState({
    currentStep: 1,
    newOnboarding: false,
    isLoading: true,
    waitingPayment: Boolean(localStorage.getItem('waiting-payment')),
  });

  const updateOnboardingState = useCallback((updates) => {
    setOnboardingState((prevState) => ({ ...prevState, ...updates }));
  }, []);

  const isStoreContextFetched = useCallback(() => {
    return (
      typeof storeContext === 'object' &&
      typeof storeContext.userStores === 'object' &&
      storeContext.userStores !== null &&
      ((storeContext.userStores &&
        typeof storeContext.storeProperties === 'object') ||
        typeof storeContext.storeProperties === 'undefined')
    );
  }, [storeContext]);

  useEffect(() => {
    if (!isStoreContextFetched()) {
      updateOnboardingState({ isLoading: Boolean(storeContext?.isLoading) });
      return;
    }

    const isStoreSelected = Boolean(
      storeContext?.storeId &&
        storeContext?.userStores?.length &&
        !storeContext?.storeProperties?.uninstalledAt,
    );
    const isMerchantBilling = Boolean(
      storeContext?.storeProperties?.plan?.chargeConfirmed &&
        storeContext?.storeProperties?.plan?.chargeUrl,
    );

    const waitingPayment = Boolean(localStorage.getItem('waiting-payment'));

    let updates = {
      isLoading: false,
      currentStep: 1,
      newOnboarding: false,
      waitingPayment,
    };

    if (!isStoreSelected) {
      updates = {
        ...updates,
        currentStep: 1,
      };
    } else if (!isMerchantBilling) {
      updates = {
        ...updates,
        currentStep: 2,
      };
    } else if (isStoreSelected && isMerchantBilling) {
      localStorage.removeItem('waiting-payment');
      updates = { ...updates, currentStep: 3 };
    } else {
      localStorage.removeItem('waiting-payment');
    }
    updateOnboardingState(updates);
  }, [storeContext, isStoreContextFetched, updateOnboardingState]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (
      storeContext?.refetchStoreProperties &&
      onboardingState.waitingPayment
    ) {
      interval = setInterval(() => {
        storeContext.refetchStoreProperties &&
          storeContext.refetchStoreProperties();
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [onboardingState.waitingPayment, storeContext]);

  useEffect(() => {
    if (onboardingState.newOnboarding) {
      localStorage.setItem('new-onboarding', 'true');
    }
  }, [onboardingState.newOnboarding, updateOnboardingState]);

  return {
    ...onboardingState,
    setCurrentStep: (step: number) =>
      updateOnboardingState({ currentStep: step }),
    enableCustomAppFlow:
      user?.metadata?.enableCustomAppFlow ||
      storeContext.storeProperties?.enableCustomAppFlow,
  };
};
