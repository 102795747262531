import React from 'react';
import { Text } from '../Text';
import useCustomApp from 'hooks/useCustomApp';
import { useStore } from 'context/store-context';

const CompleteCustomOnboardingAlert: React.FC = () => {
  const { storeId } = useStore();
  const {
    hasCompletedCustomAppSetup,
    hasPartiallyCompleted,
    hasBilling,
    hasCustomApp,
  } = useCustomApp();
  if (hasCompletedCustomAppSetup) return null;

  return (
    <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-amber-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          {storeId ? (
            <Text
              value="Important: Upgrade Required"
              type="h3"
              className="text-orange-600 font-medium"
            />
          ) : (
            <Text
              value="Attention: Complete Your Onboarding"
              type="h3"
              className="text-orange-600 font-medium"
            />
          )}

          <div className="mt-2 text-orange-700">
            {storeId ? (
              <Text
                value="Your store needs to be upgraded to the new ShipAid Custom App. The legacy app will be discontinued after February 2024."
                className="text-sm"
              />
            ) : (
              <Text
                value="To unlock the full potential of the app, please complete the onboarding process."
                className="text-sm"
              />
            )}
            <div className="flex items-center mt-3 mb-2">
              <div className="flex items-center">
                <div
                  className={`w-6 h-6 rounded-full flex items-center justify-center ${
                    hasCustomApp ? 'bg-green-500' : 'bg-amber-200'
                  }`}
                >
                  {hasCustomApp ? (
                    <svg
                      className="w-4 h-4 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <span className="text-orange-600 text-sm">1</span>
                  )}
                </div>
                <span className="ml-2 text-sm text-orange-600">
                  Install Custom App
                </span>
              </div>

              <div
                className={`mx-2 h-0.5 w-8 ${
                  hasCustomApp ? 'bg-green-500' : 'bg-amber-200'
                }`}
              />

              <div className="flex items-center">
                <div
                  className={`w-6 h-6 rounded-full flex items-center justify-center ${
                    hasBilling ? 'bg-green-500' : 'bg-amber-200'
                  }`}
                >
                  {hasBilling ? (
                    <svg
                      className="w-4 h-4 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <span className="text-orange-600 text-sm">2</span>
                  )}
                </div>
                <span className="ml-2 text-sm text-orange-600">
                  Connect Stripe
                </span>
              </div>
            </div>
            <button
              className="mt-3 bg-orange-600 text-white px-4 py-2 rounded-md text-sm hover:bg-orange-700 transition-colors"
              onClick={() => (window.location.href = '/custom-install')}
            >
              {hasPartiallyCompleted || !storeId
                ? 'Complete Now'
                : 'Upgrade Now'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteCustomOnboardingAlert;
