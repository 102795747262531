import { useCallback, useEffect, useState } from 'react';
import { PhoneIcon } from '@heroicons/react/outline';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Text } from 'components/Text';
import { BodyText } from 'components/MardownBodyText';
import { Button } from 'components/Button';
import { Tabs } from 'components/Tabs';
import { withNotification } from 'components/Notification';
import { useStore } from 'context/store-context';
import { useUser } from 'context/user-context';
import { FETCH_REQUEST_DETAILS, SEND_INSTALL_REQUEST } from 'gql/mutations';
import { NotificationTypes, apiErrorHandler, formatStatusText } from 'utils';
import { ManualInstallInstructions } from './ManualInstallInstructions';
import { GET_INSTALL_REQUESTS } from 'gql/queries';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const getStatusColor = (status: string) => {
  switch (status) {
    case 'In Progress':
      return 'bg-yellow-100 text-yellow-800';
    case 'Completed':
      return 'bg-green-100 text-green-800';
    case 'Pending':
      return 'bg-gray-100 text-gray-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

interface InstallRequest {
  content: string;
  createdAt: string;
  id: string;
  status: string;
  storeId: string;
  ticketId: string;
  ticketLink: string;
  updatedAt: string;
}

export const Install = withNotification(({ showNotification }: any) => {
  const store = useStore();
  const { user } = useUser();
  const [installRequests, setInstallRequests] = useState<InstallRequest[]>([]);
  const [selectedTab, setSelectedTab] = useState('Free Expert Installation');

  const [requestDetail, setRequestDetail] = useState<{
    status: string;
    attachments: string[];
  } | null>(null);

  const [getInstallRequests, { refetch: refetchInstallRequests }] =
    useLazyQuery(GET_INSTALL_REQUESTS, {
      variables: {
        where: {
          email: {
            _eq: user?.email,
          },
          isCustomApp: {
            _eq: true,
          },
        },
      },
      onCompleted: (data) => {
        setInstallRequests(data.installRequests);
      },
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    });

  const [fetchRequestDetails] = useMutation(FETCH_REQUEST_DETAILS, {
    onCompleted: (data) => {
      setRequestDetail(data?.fetchAsanaTicketDetails);
    },
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  useEffect(() => {
    if (installRequests && installRequests.length) {
      fetchRequestDetails({
        variables: {
          ticketId: installRequests[0].ticketId,
        },
      });
    }
  }, [installRequests, fetchRequestDetails]);

  const [sendInstallRequest, { loading: sendInstallRequestLoading }] =
    useMutation(SEND_INSTALL_REQUEST, {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted() {
        showNotification(NotificationTypes.success, 'Install request is sent!');
        refetchInstallRequests();
      },
    });

  useEffect(() => {
    getInstallRequests();
  }, [getInstallRequests]);

  const status = useCallback((request: InstallRequest) => {
    return formatStatusText(
      request.status === 'COMPLETED'
        ? 'Completed'
        : request.status === 'PENDING'
        ? 'Pending'
        : request.status,
    );
  }, []);

  const getStatusColorClasses = useCallback((request: InstallRequest) => {
    return request.status === 'COMPLETED'
      ? 'bg-green-100 text-green-800'
      : request.status === 'IN_PROGRESS'
      ? 'bg-yellow-100 text-yellow-800'
      : request.status === 'PENDING'
      ? 'bg-gray-100 text-gray-800'
      : 'bg-red-100 text-red-800';
  }, []);

  const handleSendInstallRequest = async () => {
    try {
      await sendInstallRequest({
        variables: {
          input: {
            storeId: store.storeId,
            email: user?.email,
            name: user?.displayName,
            storeName: store.storeName,
          },
        },
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  const paragraphs = [
    {
      text: `One of the ways you can install **Shipaid** is by using [**Shopify's Theme app extensions**](https://shopify.dev/apps/online-store/theme-app-extensions) which make it super easy to configure the **Shipaid** app widget without the need to manually edit your store theme's code. In your theme editor, you'll be able to select the **Shipaid** Widget from the list of available **App blocks** and drag and drop in your desired location.`,
    },
    {
      text: `Installing the **Shipaid** app in your store adds the **Shipaid widget** App block to your theme. When added to your store, the widget can be used by shoppers to add or remove shipping protection and display relevant information about the shipping protection such as price, whether or not shipping protective is active and links to learn more.`,
    },
    {
      text: `The **Shipaid** app block is designed to be used on the **Cart Template** and is best positioned in the **Subtotal** section right above the **Checkout button** block. This will allow your customers select their desired shipping protection as part of the checkout process.`,
      image: `https://res.cloudinary.com/media-resource/image/upload/v1659071448/2022-07-29_01-09-46_bdgya7.gif`,
    },
    {
      text: `To add the **Shipaid** widget block, click *Add block* on the left hand menu and in the dropdown that appears, look for the ***Shipaid** Widget* under the *Apps* section. Once you've selected the widget, you can drag it to it's desired location within the chosen section.`,
      image: `https://res.cloudinary.com/media-resource/image/upload/v1659070800/shipaid-dev/image_nhhkso.png`,
    },
    {
      text: `Modifying the Shipaid app block is easy too. To disable it, you can click on the eye icon that shows up when you hover over the app block. You can also click on the **Shipaid Widget** block to access more options including the option to delete the widget. To add the widget back again, just follow the same steps above.`,
      image: `https://res.cloudinary.com/media-resource/image/upload/v1659072512/2022-07-29_01-16-23_omjwj7.gif`,
      imageStyle: { width: 720, height: 480 },
    },
  ];

  const renderRequestCallButton = ({ className }: { className?: string }) => (
    <a
      href="https://calendly.com/shipaid-support/install"
      target="_blank"
      rel="noreferrer"
    >
      <Button className={className} isRounded onClick={() => {}}>
        <PhoneIcon className="mr-4" width={20} /> Book An Expert Call
      </Button>
    </a>
  );

  const renderRequestInstallButton = ({
    className,
    disabled = false,
    text = 'Request Free Expert Installation',
  }: {
    className?: string;
    disabled?: boolean;
    text?: string;
  }) => (
    <Button
      className={className}
      isRounded
      onClick={
        disabled || sendInstallRequestLoading
          ? () => {}
          : handleSendInstallRequest
      }
      disabled={disabled || sendInstallRequestLoading}
    >
      {sendInstallRequestLoading ? 'Please wait...' : text}
    </Button>
  );

  const renderExpertFlow = () => {
    return (
      <div className="mt-16">
        <p style={{ maxWidth: '756px', margin: 'auto' }}>
          We strongly encourage you to take advantage of our Free Expert
          Installation service to seamlessly integrate the Delivery Guarantee
          widget into your cart or checkout process. This ensures maximum
          conversion rates and a bug-free experience. After installing the app,
          you will receive a Shopify request for access to your theme code.
          Kindly approve this request, and our team will complete the
          installation within 24 hours or less. The installation will not
          disrupt your live store and will give you a chance to review it before
          it is published. If you do not receive the request, simply click the
          &ldquo;Request&ldquo; button below, and we will promptly send it your
          way. Trust our expertise for an optimized and hassle-free
          implementation.
        </p>
        <div
          className="flex justify-start mb-4"
          style={{ maxWidth: '756px', margin: 'auto' }}
        >
          {renderRequestInstallButton({
            className: 'mt-6',
            disabled: installRequests.length > 0,
            text:
              installRequests.length > 0
                ? 'Request Sent'
                : 'Request Free Expert Installation',
          })}
        </div>

        {installRequests.length > 0 && (
          <div className="mt-8 mb-8 mx-auto" style={{ maxWidth: '756px' }}>
            <h3 className="text-xl font-semibold mb-4">
              Install Request History
            </h3>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-300 shadow-sm rounded-lg overflow-hidden">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {installRequests.map((request) => (
                    <tr key={request.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(request.createdAt).toLocaleTimeString(
                          'en-US',
                          {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                          },
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColorClasses(
                            request,
                          )}`}
                        >
                          {status(request)}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        <ul>
                          <li>Ticket ID: {request.ticketId}</li>
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <p className="mt-6 mx-auto" style={{ maxWidth: '756px' }}>
          Feel free to schedule a call with our install team using be button
          below or email us at support@shipaid.com to go over any specifics.
        </p>
        <div
          className="flex justify-start mb-4"
          style={{ maxWidth: '756px', margin: 'auto' }}
        >
          {renderRequestCallButton({ className: 'mt-6' })}
        </div>
      </div>
    );
  };

  const renderAutomaticFlow = () => {
    return (
      <div className="mt-16">
        <div style={{ maxWidth: '756px', margin: 'auto' }}>
          {paragraphs.map(
            (p: {
              image?: string;
              imageStyle?: { width: number; height: number };
              text: string;
            }) => {
              return (
                <div key={p.text} className="mb-4 items-center flex flex-col">
                  {p.image && (
                    <img
                      alt={p.text}
                      style={p.imageStyle || { width: 360, height: 324 }}
                      className="my-4 border border-gray-300 object-cover block margin-auto"
                      src={p.image}
                    />
                  )}
                  <BodyText className="max-w-2xl" text={p.text} />
                </div>
              );
            },
          )}
        </div>
        <div className="flex justify-end mb-4">
          {renderRequestCallButton({ className: 'mt-6' })}
        </div>
      </div>
    );
  };

  const renderManualFlow = () => {
    return (
      <div className="mt-16">
        <ManualInstallInstructions />
        <div className="flex justify-end mb-4">
          {renderRequestCallButton({ className: 'mt-7' })}
        </div>
      </div>
    );
  };

  return (
    <div className="pb-8">
      <div className="max-w-7xl mx-auto mb-12 px-4 sm:px-6 md:px-8">
        <Text value="Install" type="h1" />

        {requestDetail && (
          <div className="max-w-full mt-2 p-6 bg-white rounded-lg shadow-lg border border-gray-200">
            <h2 className="text-xl font-semibold mb-4">
              Support Request Details
            </h2>
            <div
              className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                requestDetail.status,
              )}`}
            >
              {requestDetail.status}
            </div>

            {requestDetail.attachments &&
            requestDetail.attachments.length > 0 ? (
              <div className="mt-4 space-y-2">
                <h3 className="mt-6 text-lg font-medium">
                  Store Preview ({requestDetail.attachments.length}{' '}
                  Screenshot/s)
                </h3>
                <Swiper slidesPerView={1} navigation pagination scrollbar>
                  {requestDetail.attachments.map((attachment, index) => (
                    <SwiperSlide key={index}>
                      <div>
                        <img
                          src={attachment}
                          alt="screenshot"
                          className="w-auto h-auto"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            ) : (
              <p className="mt-4 text-gray-600">No screenshots available.</p>
            )}
          </div>
        )}
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Tabs
          tabs={[
            { name: 'Free Expert Installation' },
            { name: 'Manual Installation' },
            { name: 'Auto Installation' },
          ]}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {selectedTab === 'Auto Installation'
          ? renderAutomaticFlow()
          : selectedTab === 'Manual Installation'
          ? renderManualFlow()
          : renderExpertFlow()}
      </div>
    </div>
  );
});

export default Install;
