import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { withNotification } from 'components/Notification';
import { SEND_CUSTOM_APP_REQUEST } from 'gql/mutations';
import { NotificationTypes } from 'utils';
import { GET_INSTALL_REQUESTS } from 'gql/queries';
import { useUser } from 'context/user-context';
import { useStore } from 'context/store-context';
import { InlineWidget } from 'react-calendly';

const ExpertInstallPopup: React.FC<{
  onClose: () => void;
  showNotification: (type: string, msg: string) => void;
}> = ({ showNotification, onClose }) => {
  const { user } = useUser();
  const { storeDomain } = useStore();
  let ticketFetchInterval = useRef<NodeJS.Timer | null>(null);

  const [ticketStatus, setTicketStatus] = useState<string | null>(null);
  const [isCustomAppCreated, setIsCustomAppCreated] = useState<boolean>(false);

  const [, { refetch: refetchInstallRequests }] = useLazyQuery(
    GET_INSTALL_REQUESTS,
    {
      variables: {
        where: {
          email: {
            _eq: user?.email,
          },
          isCustomApp: {
            _eq: true,
          },
        },
      },
      onCompleted: (data) => {
        if (data?.installRequests?.length) {
          if (data.installRequests[0].status !== 'COMPLETED') {
            setTicketStatus(data.installRequests[0].status);
          } else {
            setTicketStatus(null);
            setIsCustomAppCreated(true);
          }
        } else {
          setTicketStatus(null);
        }
      },
      onError(error) {},
    },
  );

  useEffect(() => {
    if (!ticketStatus && isCustomAppCreated) {
      if (ticketFetchInterval.current)
        clearInterval(ticketFetchInterval.current);
      return;
    }

    ticketFetchInterval.current = setInterval(() => {
      refetchInstallRequests();
    }, 2000);

    return () => {
      if (ticketFetchInterval.current)
        clearInterval(ticketFetchInterval.current);
    };
  }, [isCustomAppCreated, ticketStatus, refetchInstallRequests]);

  const [
    sendCustomAppCreationRequest,
    { loading: sendCustomAppCreationRequestLoading },
  ] = useMutation(SEND_CUSTOM_APP_REQUEST, {
    onError(error) {
      showNotification(
        NotificationTypes.error,
        error?.message || 'Something went wrong, please try again',
      );
    },
    onCompleted() {
      showNotification(
        NotificationTypes.success,
        'Custom App request is sent!',
      );
    },
  });

  const handleRequestSupport = () => {
    sendCustomAppCreationRequest({
      variables: {
        input: {
          name: user?.displayName,
          email: user?.email,
          storeName: storeDomain,
        },
      },
    });
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-4xl relative">
        {/* Popup Header */}
        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
          Expert Install Options
        </h2>

        {/* Options Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Request ShipAid Support */}
          <div className="md:col-span-1 md:col-span bg-gray-50 border border-gray-200 rounded-lg p-6 flex flex-col items-center text-center shadow-sm hover:shadow-md transition-shadow">
            <h3 className="text-lg font-medium text-gray-700 mb-2">
              Request ShipAid Support
            </h3>
            <p className="text-sm text-gray-600 mb-4">
              Get personalized assistance from our support team.
            </p>
            {ticketStatus ? (
              <div className="mt-6">
                <div className="bg-blue-100 text-blue-800 p-4 rounded-md shadow-md">
                  <div className="flex justify-between items-center">
                    <p className="font-semibold text-lg">Ticket Status:</p>
                    <p className="text-sm">{ticketStatus}</p>
                  </div>
                  <p className="text-sm mt-3 text-blue-600 italic">
                    We have received your request.
                    <br />
                    Please be patient while we reach out to you.
                  </p>
                </div>
              </div>
            ) : (
              <button
                className="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
                onClick={handleRequestSupport}
                disabled={sendCustomAppCreationRequestLoading}
              >
                {sendCustomAppCreationRequestLoading
                  ? 'Sending Request...'
                  : 'Request Support'}
              </button>
            )}
          </div>

          {/* Schedule a Session */}
          <div className="md:col-span-2 md:col-span bg-gray-50 border border-gray-200 rounded-lg p-6 flex flex-col items-center text-center shadow-sm hover:shadow-md transition-shadow">
            <h3 className="text-lg font-medium text-gray-700 mb-2">
              Schedule a Session
            </h3>
            <p className="text-sm text-gray-600 mb-4">
              Book a time to connect with an expert via Calendly.
            </p>
            <div className="w-full h-[400px]" id="calendly-trigger">
              <InlineWidget
                url="https://calendly.com/shipaid/onboarding"
                styles={{
                  height: '400px',
                }}
              />
            </div>
          </div>
        </div>

        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default withNotification(ExpertInstallPopup);
