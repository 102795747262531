import React from 'react';

interface ISettingItem {
  title: string;
  description: string;
  value: boolean;
  onChangeValue: (val: boolean) => void;
}

export default function SettingItem({
  title,
  description,
  value,
  onChangeValue,
}: ISettingItem) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
      <div className="flex justify-between items-center">
        <div>
          <h5 className="text-lg font-medium text-gray-800">{title}</h5>
          <p className="text-sm text-gray-600">{description}</p>
        </div>
        <button
          onClick={onChangeValue.bind(null, !value)}
          className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors ${
            value ? 'bg-green-500' : 'bg-gray-300'
          }`}
          aria-checked={value}
          role="switch"
        >
          <span
            className={`inline-block w-4 h-4 transform rounded-full bg-white transition-transform ${
              value ? 'translate-x-5' : 'translate-x-1'
            }`}
          />
        </button>
      </div>
    </div>
  );
}
