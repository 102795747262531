import React, { useEffect } from 'react';
import Onboarding from 'components/CustomAppOnboarding';
import useCustomApp from 'hooks/useCustomApp';
import { useNavigate } from 'react-router';

export const CustomAppOnboarding = () => {
  const { isLoading, enableCustomAppFlow } = useCustomApp();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !enableCustomAppFlow) {
      navigate('/dashboard');
    }
  }, [enableCustomAppFlow, navigate, isLoading]);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="flex items-center"></div>
        <Onboarding />
      </div>
    </div>
  );
};
