import React, { ChangeEvent, useCallback, useState } from 'react';
import { Modal } from './Modal';
import { Text } from './Text';
import { Button } from './Button';
import { useMutation } from '@apollo/client';
import { FETCH_PRODUCT_VARIANT, REPLACE_PRODUCT_VARIANT } from 'gql/mutations';
import { ClaimItemValues } from 'types/claim';

const ReplaceResolutionVariant = ({
  storeId,
  claimItem,
}: {
  storeId: string;
  claimItem: ClaimItemValues;
}) => {
  const [sku, setSKU] = useState<string>('');

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [variant, setVariant] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const onOpenModal = () => setIsOpen(true);

  const [fetchProductVariant, { loading: fetchProductVariantLoading }] =
    useMutation(FETCH_PRODUCT_VARIANT, {
      onCompleted: (data) => {
        setError(null);
        setVariant(data?.fetchProductVariant);
      },
      onError: () => {
        setError(`No product variant found for Sku: ${sku}`);
        setVariant(null);
      },
    });
  const [replaceProductVariant, { loading: fetchReplaceProductVariant }] =
    useMutation(REPLACE_PRODUCT_VARIANT, {
      onCompleted: (data) => {
        if (data) {
          setIsOpen(false);
          window.location.reload();
        }
      },
    });

  const handleReplaceVariant = () => {
    replaceProductVariant({
      variables: {
        input: {
          storeId,
          orderItemId: claimItem.orderItem.id,
          title: variant.title,
          sku: variant.sku,
          imgSrc: variant.image.url,
          variantId: variant.id,
        },
      },
    });
  };

  const handleSearchSKU = (e: ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;

    setSKU(val);

    if (!val) {
      setError(null);
      setVariant(null);
    }
  };

  const onSearchVariant = useCallback(() => {
    if (sku) {
      fetchProductVariant({
        variables: {
          input: {
            storeId,
            sku,
          },
        },
      });
    } else {
      setVariant(null);
      setError(null);
    }
  }, [sku, storeId, fetchProductVariant, setVariant]);
  return (
    <div>
      <button
        className="bg-transparent text-orange-500 font-bold"
        onClick={onOpenModal}
      >
        Replace Order Item
      </button>

      <Modal
        openModal={(open) => setIsOpen(open)}
        open={isOpen}
        containerClass="sm:max-w-xl sm:w-full"
      >
        <Text value="Replace Order Item" type="h4" className="my-2" />
        <div className="block sm:flex sm:items-center mb-2">
          <Text
            value="SKU of the product/variant"
            type="sm"
            className="text-gray-500 mr-3 mb-2 sm:mb-0"
          />
          <div className="flex">
            <input
              type="text"
              name="sku"
              className="border border-1 shadow-sm focus:ring-none focus:border-none block sm:text-sm border-gray-300 rounded-md rounded-tr-none rounded-br-none p-2"
              placeholder="SKU of the product/variant"
              value={sku}
              onChange={handleSearchSKU}
            />
            <Button
              onClick={onSearchVariant}
              className="rounded-tl-none rounded-bl-none"
            >
              Submit
            </Button>
          </div>
        </div>
        {fetchProductVariantLoading ? (
          <div>Please wait...</div>
        ) : variant ? (
          <div className="flex justify-start items-center my-2 mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
            <img
              className="w-auto h-24 object-cover"
              src={variant.image?.url}
              alt="Product"
            />

            <div className="p-2">
              <h2 className="text-xl font-semibold text-gray-800 truncate">
                {variant?.title}
              </h2>
              <p className="text-sm text-gray-500 mt-2">{variant?.sku}</p>
              <p className="text-lg font-bold text-green-600 mt-4">
                ${variant?.price}
              </p>
            </div>
          </div>
        ) : sku && error ? (
          <div className="text-red-300 font-bold">{error}</div>
        ) : null}
        <div className="flex mt-6">
          <Button
            onClick={handleReplaceVariant}
            className="bg-blue-600 mr-3 hover:bg-blue-700"
            isLoading={false}
            disabled={!variant || fetchReplaceProductVariant}
          >
            {fetchReplaceProductVariant ? 'Please wait...' : 'Replace'}
          </Button>
          <Button
            onClick={() => setIsOpen(false)}
            className="bg-transparent border border-rose-500 text-gray-500 hover:bg-rose-100"
          >
            <Text value="Cancel" className="text-rose-500" />
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ReplaceResolutionVariant;
