import { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import {
  PieChart,
  Pie,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  Legend,
} from 'recharts';
import { Text } from 'components/Text';
import { Stats } from 'components/Stats';
import { DatePicker } from 'components/DatePicker';
import { LoadingScreen } from 'components/LoadingScreen';
import { dashboardStats } from './data/dashboard-data';
import {
  formatPieData,
  formatProtectedOrdersandClaimsGraphData,
  apiErrorHandler,
  NotificationTypes,
  getDateXDaysInFuture,
  getDateXDaysAgo,
  getStoreCurrencyFormatter,
  DateRangeOption,
  getStartAndEndDate,
} from 'utils';
import { useStore } from 'context/store-context';
import { format, endOfDay } from 'date-fns';
import { GET_DASHBOARD_ANALYTICS } from 'gql/queries';
import { useLazyQuery } from '@apollo/client';
import { withNotification } from 'components/Notification';
import { Dropdown } from 'components/Dropdown';
import { Order } from 'types/order';
import { Claim } from 'types/claim';
import { CustomerPortalBtn } from 'components/CustomerPortalBtn';
import { TrackingPortalBtn } from 'components/TrackingPortalBtn';
import CompleteCustomOnboardingAlert from 'components/CustomAppOnboarding/CompleteCustomOnboardingAlert';
import { useUser } from 'context/user-context';
import CompleteOnboardingAlert from 'components/AppOnboarding/CompleteOnboardingAlert';

const dateRangeOptions: DateRangeOption[] = [
  DateRangeOption.Today,
  DateRangeOption.Yesterday,
  DateRangeOption.Last7Days,
  DateRangeOption.Last30Days,
  DateRangeOption.Last90Days,
];

export const Dashboard = withNotification(({ showNotification }: any) => {
  const { user } = useUser();

  const [dateRangeStats, setDateRangeStats] = useState<
    [Date | null, Date | null]
  >(getStartAndEndDate(DateRangeOption.Last30Days));
  const [barData, setBarData] = useState({
    protectedOrders: [],
    claimsPaid: [],
  });
  const [conversionRateStats, setConversionRateStats] = useState<any>([]);
  const [revenueVClaimsCostStats, setRevenueVClaimsCostStats] = useState<any>(
    [],
  );
  const [selectedDateRangeOption, setSelectedDateRangeOption] =
    useState<DateRangeOption>(DateRangeOption.Last30Days);

  // data will contain our todos array
  const {
    storeAnalytics,
    analyticsLoading,
    getStoreAnalytics,
    storeProperties,
    storeId,
  } = useStore();

  const [getDashboardAnalytics] = useLazyQuery(GET_DASHBOARD_ANALYTICS, {
    onCompleted: (data) => {
      setBarData(data);
    },
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  const generateConversionRateBreakdownStats = (
    {
      startDate,
      endDate,
      dateFormatOptions,
      totalOrders,
      protectedOrders,
    }: {
      startDate: Date;
      endDate: Date;
      dateFormatOptions: Intl.DateTimeFormatOptions;
      totalOrders: any;
      protectedOrders: any;
    },
    interval: 'WEEKLY' | 'DAILY' | 'MONTHLY',
  ) => {
    // take a start date, add the interval days, get the date, create a string label.
    // repeat from current end and make that the new start date, if the start date is still before end, repeat,
    // if not, make the end date the new end date, create a string label.

    let intervalInDays;

    if (interval === 'WEEKLY') {
      intervalInDays = 7;
    } else if (interval === 'DAILY') {
      intervalInDays = 1;
    } else if (interval === 'MONTHLY') {
      intervalInDays = 30;
    } else {
      intervalInDays = 7;
    }

    function getOrdersCount(
      orders: any,
      filterCb: () => (c: string) => boolean,
    ) {
      return orders.filter(filterCb()).length;
    }

    if (interval !== 'DAILY') {
      const generatedStats = [];

      let intervalStartDate = startDate;
      // this is just to fulfill TS not liking this to be a null value at first.
      let intervalEndDate = startDate;

      do {
        intervalEndDate = getDateXDaysInFuture(
          intervalInDays,
          intervalStartDate,
        );

        // now we know our interval start and end date, we could actually just filter total orders and protected orders and
        // push them into the right interval collection

        // this filter returns all orders within the supplied date range
        const filterCb =
          (intervalStartDate: Date, intervalEndDate: Date) =>
          () =>
          (createdAtStr: any) => {
            const createdAt = new Date(createdAtStr);
            // we need to get the dates from midnight to avoid inconsistencies
            createdAt.setHours(0, 0, 0, 0);
            intervalStartDate.setHours(0, 0, 0, 0);
            intervalEndDate.setHours(0, 0, 0, 0);

            return (
              createdAt.getTime() >= intervalStartDate.getTime() &&
              createdAt.getTime() <= intervalEndDate.getTime()
            );
          };

        const totalOrdersCount = getOrdersCount(
          totalOrders,
          filterCb(intervalStartDate, intervalEndDate),
        );

        const protectedOrdersCount = getOrdersCount(
          protectedOrders,
          filterCb(intervalStartDate, intervalEndDate),
        );

        generatedStats.push({
          label: `${intervalStartDate.toLocaleDateString(
            undefined,
            dateFormatOptions,
          )}-${intervalEndDate.toLocaleDateString(
            undefined,
            dateFormatOptions,
          )}`,
          totalOrdersCount,
          protectedOrdersCount,
          'Conversion Rate':
            Math.round((protectedOrdersCount / totalOrdersCount) * 100) || 0,
        });

        // now make intervalStartDate the day after our end date
        // and start the loop again so long as the end date is less
        // than the proposed end date
        intervalStartDate = getDateXDaysInFuture(1, intervalEndDate);
      } while (intervalEndDate.getTime() < endDate.getTime());

      return generatedStats;
    } else {
      // if the breakdown is by each day it's not a range
      // and the filtering logic needs to be handled a bit differently.
      const generatedStats = [];
      let intervalStartDate = startDate;

      do {
        // Here we look for days that match the same day as the interval
        // since it's not a range, we match the date object converted to date strings
        const filterCb =
          (intervalStartDate: Date) => () => (createdAtStr: string) => {
            const createdAt = new Date(createdAtStr);
            return (
              intervalStartDate.toLocaleDateString() ===
              createdAt.toLocaleDateString() // e.g. 07/09/2022
            );
          };

        const totalOrdersCount = getOrdersCount(
          totalOrders,
          filterCb(intervalStartDate),
        );
        const protectedOrdersCount = getOrdersCount(
          protectedOrders,
          filterCb(intervalStartDate),
        );

        generatedStats.push({
          label: intervalStartDate.toLocaleDateString(
            undefined,
            dateFormatOptions,
          ),
          totalOrdersCount,
          protectedOrdersCount,
          'Conversion Rate':
            Math.round((protectedOrdersCount / totalOrdersCount) * 100) || 0,
        });
        intervalStartDate = getDateXDaysInFuture(1, intervalStartDate);
      } while (intervalStartDate.getTime() < endDate.getTime());

      return generatedStats;
    }
  };

  const generateRevenueVClaimsCostBreakdownStats = (
    {
      startDate,
      endDate,
      dateFormatOptions,
      barData: { protectedOrders, claimsPaid },
    }: {
      startDate: Date;
      endDate: Date;
      dateFormatOptions: Intl.DateTimeFormatOptions;
      barData: {
        protectedOrders: never[];
        claimsPaid: never[];
      };
    },
    interval: 'WEEKLY' | 'DAILY' | 'MONTHLY',
  ) => {
    // take a start date, add the interval days, get the date, create a string label.
    // repeat from current end and make that the new start date, if the start date is still before end, repeat,
    // if not, make the end date the new end date, create a string label.

    let intervalInDays;

    if (interval === 'WEEKLY') {
      intervalInDays = 7;
    } else if (interval === 'DAILY') {
      intervalInDays = 1;
    } else if (interval === 'MONTHLY') {
      intervalInDays = 30;
    } else {
      intervalInDays = 7;
    }

    function getRevenue(
      orders: never[],
      filterCb: () => (c: string) => boolean,
    ) {
      return orders
        .filter(filterCb())
        .reduce((total: number, order: Order) => {
          return total + order.protectionTotal;
        }, 0)
        .toFixed(2);
    }

    function getClaimsCost(
      claimsPaid: never[],
      filterCb: () => (c: string) => boolean,
    ) {
      return claimsPaid
        .filter(filterCb())
        .reduce((total: number, claim: Claim) => {
          return total + claim.total;
        }, 0)
        .toFixed(2);
    }

    if (interval !== 'DAILY') {
      const generatedStats = [];

      let intervalStartDate = startDate;
      // this is just to fulfill TS not liking this to be a null value at first.
      let intervalEndDate = startDate;

      do {
        intervalEndDate = getDateXDaysInFuture(
          intervalInDays,
          intervalStartDate,
        );

        // now we know our interval start and end date, we could actually just filter total orders and protected orders and
        // push them into the right interval collection

        // this filter returns all orders within the supplied date range
        const filterCb =
          (intervalStartDate: Date, intervalEndDate: Date) =>
          () =>
          (orderOrClaim: any) => {
            const createdAtStr = orderOrClaim.createdAt;
            const createdAt = new Date(createdAtStr);

            createdAt.setHours(0, 0, 0, 0);
            intervalStartDate.setHours(0, 0, 0, 0);
            intervalEndDate.setHours(0, 0, 0, 0);
            return (
              createdAt.getTime() >= intervalStartDate.getTime() &&
              createdAt.getTime() <= intervalEndDate.getTime()
            );
          };

        const protectionRevenue = getRevenue(
          protectedOrders,
          filterCb(intervalStartDate, intervalEndDate),
        );

        const claimsCost = getClaimsCost(
          claimsPaid,
          filterCb(intervalStartDate, intervalEndDate),
        );

        generatedStats.push({
          label: `${intervalStartDate.toLocaleDateString(
            undefined,
            dateFormatOptions,
          )}-${intervalEndDate.toLocaleDateString(
            undefined,
            dateFormatOptions,
          )}`,
          'Guarantee Revenue': protectionRevenue,
          'Issues Cost': claimsCost,
        });

        // now make intervalStartDate the day after our end date
        // and start the loop again so long as the end date is less
        // than the proposed end date
        intervalStartDate = getDateXDaysInFuture(1, intervalEndDate);
      } while (intervalEndDate.getTime() < endDate.getTime());

      return generatedStats;
    } else {
      const generatedStats = [];
      let intervalStartDate = startDate;

      do {
        // Here we look for days that match the same day as the interval
        // since it's not a range, we match the date object converted to date strings
        const filterCb =
          (intervalStartDate: Date) => () => (orderOrClaim: any) => {
            const createdAtStr = orderOrClaim.createdAt;
            const createdAt = new Date(createdAtStr);
            return (
              intervalStartDate.toLocaleDateString() ===
              createdAt.toLocaleDateString() // e.g. 07/09/2022
            );
          };

        const protectionRevenue = getRevenue(
          protectedOrders,
          filterCb(intervalStartDate),
        );

        const claimsCost = getClaimsCost(
          claimsPaid,
          filterCb(intervalStartDate),
        );

        generatedStats.push({
          label: intervalStartDate.toLocaleDateString(
            undefined,
            dateFormatOptions,
          ),
          'Guarantee Revenue': protectionRevenue,
          'Issues Cost': claimsCost,
        });
        intervalStartDate = getDateXDaysInFuture(1, intervalStartDate);
      } while (intervalStartDate.getTime() < endDate.getTime());

      return generatedStats;
    }
  };

  useEffect(() => {
    if (!storeAnalytics) return;
    const [startDate, endDate] = dateRangeStats;

    const totalOrders = storeAnalytics.totalOrders.nodes.map(
      (o: any) => o.createdAt,
    );
    const protectedOrders = storeAnalytics.protectedOrders.nodes.map(
      (o: any) => o.createdAt,
    );

    if (startDate && endDate) {
      // we need to check the date formatting based on diff in start and end date
      const differenceInYears =
        endDate?.getFullYear() - startDate.getFullYear();
      const dateFormatOptions: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: differenceInYears ? '2-digit' : undefined,
      };
      // first we need to know how many days exist between the start and end dates
      let differenceInMilliseconds = endDate?.getTime() - startDate.getTime();
      const differenceInDays = Math.ceil(
        differenceInMilliseconds / (1000 * 3600 * 24),
      );

      // if less than or equal to 7, break into days,
      if (differenceInDays <= 7) {
        setConversionRateStats(
          generateConversionRateBreakdownStats(
            {
              startDate,
              endDate,
              dateFormatOptions,
              totalOrders,
              protectedOrders,
            },
            'DAILY',
          ),
        );

        setRevenueVClaimsCostStats(
          generateRevenueVClaimsCostBreakdownStats(
            {
              startDate,
              endDate,
              dateFormatOptions,
              barData,
            },
            'DAILY',
          ),
        );
      }
      // if more than 7 but less than or equal to 31 break into weeks
      else if (differenceInDays > 7 && differenceInDays <= 31) {
        // break up total orders into time period
        setConversionRateStats(
          generateConversionRateBreakdownStats(
            {
              startDate,
              endDate,
              dateFormatOptions,
              totalOrders,
              protectedOrders,
            },
            'WEEKLY',
          ),
        );

        setRevenueVClaimsCostStats(
          generateRevenueVClaimsCostBreakdownStats(
            {
              startDate,
              endDate,
              dateFormatOptions,
              barData,
            },
            'WEEKLY',
          ),
        );
      }
      // if more than 31 break into months
      else if (differenceInDays > 31) {
        setConversionRateStats(
          generateConversionRateBreakdownStats(
            {
              startDate,
              endDate,
              dateFormatOptions,
              totalOrders,
              protectedOrders,
            },
            'MONTHLY',
          ),
        );

        setRevenueVClaimsCostStats(
          generateRevenueVClaimsCostBreakdownStats(
            {
              startDate,
              endDate,
              dateFormatOptions,
              barData,
            },
            'MONTHLY',
          ),
        );
      }
    }
  }, [dateRangeStats, storeAnalytics, barData]);

  useEffect(() => {
    storeId &&
      getDashboardAnalytics({
        variables: {
          storeId,
          startDate: format(
            endOfDay(
              dateRangeStats[0] ? dateRangeStats[0] : getDateXDaysAgo(30),
            ),
            'yyyy-MM-dd',
          ),
          endDate: format(
            endOfDay(
              dateRangeStats[1] ? dateRangeStats[1] : getDateXDaysAgo(0),
            ),
            'yyyy-MM-dd',
          ),
        },
      });
  }, [getDashboardAnalytics, storeId, dateRangeStats]);

  const handleSetDate = (dateArray: any) => {
    setDateRangeStats(dateArray);
    if (!dateArray[0] && !dateArray[1]) {
      return (
        getStoreAnalytics &&
        getStoreAnalytics(
          format(endOfDay(getDateXDaysAgo(30)), 'yyyy-MM-dd'),
          format(endOfDay(getDateXDaysAgo(0)), 'yyyy-MM-dd'),
        )
      );
    }
    if (dateArray[1])
      return (
        getStoreAnalytics &&
        getStoreAnalytics(
          format(endOfDay(new Date(dateArray[0])), 'yyyy-MM-dd'),
          format(endOfDay(new Date(dateArray[1])), 'yyyy-MM-dd'),
        )
      );
  };

  const showCustomAppFlow = useMemo(() => {
    return (
      user?.metadata?.enableCustomAppFlow ||
      storeProperties?.enableCustomAppFlow
    );
  }, [user, storeProperties]);

  if (analyticsLoading) return <LoadingScreen />;

  const protecedOrdersChartData = barData
    ? formatProtectedOrdersandClaimsGraphData(
        barData,
        dateRangeStats[0],
        dateRangeStats[1],
      )?.protectedOrdersGraphData
    : [];

  const hasStoreAnalytics = Boolean(
    storeAnalytics?.unprotectedOrders.aggregate.total === 0 &&
      storeAnalytics.protectedOrders.aggregate.total === 0,
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      {showCustomAppFlow ? (
        <CompleteCustomOnboardingAlert />
      ) : (
        <CompleteOnboardingAlert />
      )}

      <Text className="mb-4" value="Analytics" type="h4" />

      <div className="md:flex items-center justify-between mb-5">
        <div className="md:flex items-center">
          <DatePicker
            selectsRange
            startDate={dateRangeStats[0]}
            endDate={dateRangeStats[1]}
            setValue={handleSetDate}
            placeHolder="Select date Range"
            dateFormat="MM/dd/yy"
            showYearDropdown
            isClearable
            className={classNames(
              dateRangeStats[0] && dateRangeStats[0] ? 'w-72' : 'w-48',
            )}
          />
          <Dropdown
            options={dateRangeOptions}
            onSelectOption={(option) => {
              setSelectedDateRangeOption(option as DateRangeOption);
              // now we get a start and end date based on the selected date range
              handleSetDate(getStartAndEndDate(option as DateRangeOption));
            }}
            btnClassName="border border-gray-300 h-12 ml-4"
            placeholder="select currency"
            selectedOption={selectedDateRangeOption}
            downCaret
          />
        </div>
        <div>
          <TrackingPortalBtn />
          <CustomerPortalBtn />
        </div>
      </div>
      <div className="flex lg:flex-row flex-col px-2 my-12">
        <div
          className="bg-white shadow overflow-hidden sm:rounded-lg p-4 lg:basis-1/2 basis-1/2 mt-10 md:mt-0 sm:mr-4"
          style={{ minWidth: 240 }}
        >
          <Text
            value="Guarantee Conversion Rate"
            type="bold"
            className="text-blue-700"
          />
          <ResponsiveContainer height={360} width="100%" className="mt-5">
            <LineChart data={conversionRateStats}>
              <Line
                dataKey="Conversion Rate"
                stroke="#1D4ED8"
                strokeWidth={2}
              />
              <XAxis dataKey="label" />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
              <Legend />
              <YAxis tickFormatter={(tick) => `${tick}%`} />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const protectedOrdersCount =
                      payload[0]?.payload?.protectedOrdersCount;
                    const totalOrdersCount =
                      payload[0]?.payload?.totalOrdersCount;

                    return (
                      <div className="custom-tooltip border p-4 border-gray-400 bg-white">
                        <p className="label text-blue-600">{`Guaranteed Orders : ${protectedOrdersCount}`}</p>
                        <p className="label text-blue-600">{`Total Orders : ${totalOrdersCount}`}</p>
                        <p className="label text-blue-800 font-bold">{`Conversion Rate : ${payload[0]?.value}%`}</p>
                      </div>
                    );
                  }

                  return null;
                }}
                cursor={{ fill: 'rgb(37,100,235, 0.3)' }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4 lg:basis-1/2 basis-1/2 mt-10 md:mt-0 sm:mr-4">
          <Text
            value="Guarantee Revenue vs. Issues Cost"
            type="bold"
            className="text-blue-700"
          />
          <ResponsiveContainer height={360} width="100%" className="mt-5">
            <BarChart data={revenueVClaimsCostStats}>
              <XAxis dataKey="label" />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
              <YAxis />
              <XAxis dataKey="label" />
              <Legend />
              <Bar dataKey="Guarantee Revenue" stackId="a" fill="#2564eb" />
              <Bar dataKey="Issues Cost" stackId="a" fill="#3a3a3a" />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const protectionRevenue =
                      payload[0]?.payload?.['Guarantee Revenue'];
                    const claimsCost = payload[0]?.payload?.['Issues Cost'];

                    return (
                      <div className="custom-tooltip border p-4 border-gray-400 bg-white">
                        <p className="label text-blue-600">{`Guarantee Revenue : ${getStoreCurrencyFormatter(
                          storeProperties?.currency,
                          protectionRevenue,
                        )}`}</p>
                        <p className="label text-blue-800">{`Issues Cost : ${getStoreCurrencyFormatter(
                          storeProperties?.currency,
                          claimsCost,
                        )}`}</p>
                      </div>
                    );
                  }

                  return null;
                }}
                cursor={{ fill: 'rgb(37,100,235, 0.3)' }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col px-2">
        <div
          className="bg-white shadow overflow-hidden sm:rounded-lg p-4 lg:basis-1/2 basis-1/2 mt-10 md:mt-0 sm:mr-4"
          style={{ minWidth: 240 }}
        >
          <Text value="Orders" type="bold" className="text-blue-700" />
          {hasStoreAnalytics ? (
            <div className="flex justify-center items-center mt-16">
              <Text
                value="No Data available"
                type="body"
                className="text-gray-500"
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <ResponsiveContainer
                height={360}
                width="100%"
                className="text-center mt-5 ml-8 flex justify-center"
              >
                <PieChart height={360} width={360}>
                  <Pie
                    dataKey="value"
                    data={storeAnalytics ? formatPieData(storeAnalytics) : []}
                    innerRadius={35}
                    outerRadius={100}
                    className="border"
                  />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4 lg:basis-1/2 mt-10 md:mt-0">
          <Text
            value="Guaranteed Orders"
            type="bold"
            className="text-blue-700"
          />
          <ResponsiveContainer height={360} width="100%" className="mt-5">
            <BarChart data={protecedOrdersChartData}>
              <Bar dataKey="orders" fill="#1D4ED8" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip cursor={{ fill: 'rgb(37,100,235, 0.3)' }} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <Stats
        stats={
          storeAnalytics
            ? dashboardStats(storeAnalytics, barData, storeProperties?.currency)
            : []
        }
        isSpaced
        cardClassName="py-5 h-40 m-2"
      />
    </div>
  );
});
